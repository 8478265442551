export const environment = {
    production: false,
    cofferRestUrl: 'https://api.staging.digicoffer.com/',
    xmppDomain: 'chat.vitacape.com',
    DOC2FILE :'https://dev.utils.doc2pdf.digicoffer.com/api/v1/docfile2pdf',
    DOC2PDF:'https://dev.utils.doc2pdf.digicoffer.com/api/v1/doc2pdf',
    cofferPersonalUrl: 'https://api.staging.digicoffer.com/consumer/sprelationship/',
    vitacapeRestUrl:'https://devapi.vitacape.digisecitus.com/api/v1/',
    AVChat:'https://dev.testavchat.digicoffer.com/',
    payment:'https://dev2.payment.digicoffer.com/',
    appVersion: require('../../package.json').version + '-dev'
  };